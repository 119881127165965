import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ListItem, NumberedList, Link } from '@entur/typography';
import '../../sales/guides.scss';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "transport-operator-guide"
    }}>{`Transport operator guide`}</h1>
    <p>{`This is a guide for how transport operators can make their products available for sale through the Entur Sales Platform.`}</p>
    <p>{`All transport operators who wish to make their products available for sale through our platform must support the TOMP API standard.`}</p>
    <NumberedList className="to-guides-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#tomp-a-europeen-api-standard" mdxType="Link">
            TOMP: A Europeen API standard
        </Link>
        <NumberedList className="to-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#what-is-the-tomp-standard" mdxType="Link">What is the TOMP standard</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#why-did-we-choose-this-standard" mdxType="Link">Why did we choose this standard</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#why-integrating-with-the-tomp-standard-is-a-smart-business-move" mdxType="Link">
                    Why Integrating with the TOMP Standard is a Smart Business Move
                </Link>
                <NumberedList className="to-guides-toc" mdxType="NumberedList">
                    <ListItem mdxType="ListItem">
                        <Link href="#why-the-tomp-standard-is-the-right-choice-and-what-it-offers" mdxType="Link">
                            Why the TOMP Standard is the Right Choice and What It Offers
                        </Link>
                    </ListItem>
                </NumberedList>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#our-reference-application" mdxType="Link">
            Our reference application
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#realtime-data" mdxType="Link">
            Realtime data
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#implementation" mdxType="Link">
            Implementation
        </Link>
        <NumberedList className="to-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#get-meta-data-describes-the-running-implementations" mdxType="Link">
                    Get meta data (describes the running implementations)
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#get-vehicleidassetid-gbfs-given-qr-code" mdxType="Link">
                    Get vehicleId/assetId (GBFS) given QR-code
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#create-a-booking" mdxType="Link">
                    Create a booking
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#change-the-state-of-a-leg" mdxType="Link">
                    Change the state of a leg
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#get-a-leg-a-booking-or-all-bookings" mdxType="Link">
                    Get a leg, a booking or all bookings
                </Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    </NumberedList>
    <h2 {...{
      "id": "tomp-a-europeen-api-standard"
    }}>{`TOMP: A Europeen API standard`}</h2>
    <p>{`TOMP = Transport Operator MaaS Provider.`}</p>
    <h3 {...{
      "id": "what-is-the-tomp-standard"
    }}>{`What is the TOMP standard`}</h3>
    <p>{`TOMP (Transport Operator to Mobility-as-a-Service Provider) is a European API standard designed to enable seamless integration
and communication between transport operators and Mobility-as-a-Service (MaaS) providers. It allows for functions like booking, payment,
and real-time data sharing, helping to create a unified, user-friendly mobility experience across Europe by connecting various transport services
within a single application.`}</p>
    <ul>
      <li parentName="ul">{`Read more about TOMP here: `}<a parentName="li" {...{
          "href": "https://github.com/TOMP-WG"
        }}>{`https://github.com/TOMP-WG`}</a></li>
      <li parentName="ul">{`The TOMP swagger doc: `}<a parentName="li" {...{
          "href": "https://app.swaggerhub.com/apis-docs/TOMP-API-WG/transport-operator_maas_provider_api/1.5.0#/"
        }}>{`https://app.swaggerhub.com/apis-docs/TOMP-API-WG/transport-operator_maas_provider_api/1.5.0#/`}</a></li>
    </ul>
    <h3 {...{
      "id": "why-did-we-choose-this-standard"
    }}>{`Why did we choose this standard`}</h3>
    <p>{`As a public transport company, we want to use standards to avoid 1-1 implementations between different services.
The TOMP API standard is a well-established standard with over 40 users spread over various countries in Europe and is a
standard based on `}<a parentName="p" {...{
        "href": "https://gbfs.org/"
      }}>{`GBFS`}</a>{` data. Since almost all data provided by shared mobility actors is based on GBFS data,
this also makes the implementation of the standard much easier as you do not need to convert between different data models.`}</p>
    <h3 {...{
      "id": "why-integrating-with-the-tomp-standard-is-a-smart-business-move"
    }}>{`Why Integrating with the TOMP Standard is a Smart Business Move`}</h3>
    <p>{`Integrating with the Shared Mobility API, built on the proven TOMP standard, opens doors to new customers and strengthens your
collaboration with public transport providers in key municipalities and cities. Entering a Maas-offering platform will significantly
increase your exposure to new customers by participating in a bigger marketplace.`}</p>
    <p>{`By aligning with this standard, your service becomes part of a cohesive mobility offering, making it easier for residents and visitors
to discover and use your solutions. Municipalities increasingly favor operators who integrate with shared mobility frameworks, as it
ensures better accessibility, reliability, and user experience for their citizens. Moreover, you position your business as a trusted
and forward-thinking partner, securing both market growth and long-term cooperation with public transport providers.`}</p>
    <p>{`In addition, as mobility trends evolve, the TOMP standard is actively maintained and updated to accommodate new technologies and use cases.
By integrating now, you prepare your service for continued relevance in the rapidly changing mobility landscape.`}</p>
    <h4 {...{
      "id": "why-the-tomp-standard-is-the-right-choice-and-what-it-offers"
    }}>{`Why the TOMP Standard is the Right Choice and What It Offers`}</h4>
    <ol>
      <li parentName="ol">{`Enhanced Interoperability: The TOMP standard is designed to ensure seamless communication between mobility operators and public
transport providers. This reduces technical barriers and simplifies integration, saving time and resources while ensuring your
service is ready for future scalability.`}</li>
      <li parentName="ol">{`Access to a Growing Network: By adopting the TOMP standard, your service becomes part of a unified ecosystem that connects operators,public
transport authorities, and end users. This increases visibility and access to new customer groups, hence a potential for top linegrowth.`}</li>
      <li parentName="ol">{`Streamlined Collaboration: The standard fosters closer collaboration with public transport companies, ensuring smoother partnerships and
alignment with local mobility strategies. This collaboration helps build trust and enhances your position in competitive markets.`}</li>
      <li parentName="ol">{`Regulatory Compliance: Municipalities and transport authorities often favor operators who adhere to established standards like TOMP when
issuing permits. This alignment can improve your chances of securing concessions.`}</li>
      <li parentName="ol">{`Improved User Experience: TOMP ensures that users can plan, book, and pay for services through unified systems, reducing friction and
increasing satisfaction. This makes your service more attractive to both new and existing customers.`}</li>
      <li parentName="ol">{`Future-Proofing Your Service: As mobility trends evolve, the TOMP standard is actively maintained and updated to accommodate new
technologies and use cases. By integrating now, you prepare your service for continued relevance in the rapidly changing mobility landscape.`}</li>
      <li parentName="ol">{`Sustainability Alignment: Many municipalities prioritize sustainable transport solutions. Integrating with the TOMP standard positions
your service as part of a sustainable ecosystem, enhancing your brand image and appeal to environmentally conscious users.`}</li>
    </ol>
    <h2 {...{
      "id": "our-reference-application"
    }}>{`Our reference application`}</h2>
    <p>{`Team shared mobility at Entur have created a open source reference application with all needed controllers, data classes and
services with test data as a Kotlin Spring Boot application. We have also written a guide in the README with information about
what kind of endpoints you as a Transport Operator need to implement. Since the TOMP-standard supports all kinds of mobilities,
and we (as of 20th August 2024) only supports micro-mobility, the amount of endpoints and response data is at a minimum.`}</p>
    <ul>
      <li parentName="ul">{`Application: `}<a parentName="li" {...{
          "href": "https://github.com/entur/shared-mobility-to-ref"
        }}>{`https://github.com/entur/shared-mobility-to-ref`}</a></li>
      <li parentName="ul">{`Swagger doc: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility-to-ref"
        }}>{`https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility-to-ref`}</a></li>
    </ul>
    <h2 {...{
      "id": "realtime-data"
    }}>{`Realtime data`}</h2>
    <p>{`Since all shared mobility operators in Norway are required by law to deliver realtime data to Entur, our team and clients uses
the `}<a parentName="p" {...{
        "href": "https://developer.entur.org/pages-mobility-docs-mobility-v2"
      }}>{`Mobility API`}</a>{` to receive information about available assets,
system information, vehicle types, pricing plans, system regions and geofencing zones. Because of this a lot of TOMP-endpoints
becomes redundant, which also makes the implementation of the standard easier for transport operators.`}</p>
    <h2 {...{
      "id": "implementation"
    }}>{`Implementation`}</h2>
    <p>{`As mentioned, we have created an open source project who works as a reference app on how to implement the TOMP API standard.
Take a look at the README.md in the `}<a parentName="p" {...{
        "href": "https://github.com/entur/shared-mobility-to-ref/blob/main/README.md"
      }}>{`shared-mobility-to-ref`}</a>{`
application for how to implement all the required endpoints.`}</p>
    <h4 {...{
      "id": "dictionary"
    }}>{`Dictionary`}</h4>
    <ul>
      <li parentName="ul">{`Booking: General information about the booking`}</li>
      <li parentName="ul">{`Booking.id: Unique identifier (UUID) for the booking`}</li>
      <li parentName="ul">{`Booking.state: The state of the booking`}</li>
      <li parentName="ul">{`Booking.departureTime: When the booking was created`}</li>
      <li parentName="ul">{`Booking.arrivalTime: When the booking was ended`}</li>
      <li parentName="ul">{`Booking.from: Where the trip started (coordinates)`}</li>
      <li parentName="ul">{`Booking.to: Where the trip ended (coordinates), not required`}</li>
      <li parentName="ul">{`Booking.pricing: The total amount of all the legs. Should be set after each leg have state START_FINISHING (ready for a picture of the asset)`}</li>
      <li parentName="ul">{`Booking.customer: Information about the customer. Usually only a ID`}</li>
      <li parentName="ul">{`Booking.legs: General information about a part of the trip. Usually only one leg on a booking with an asset (vehicle)`}</li>
      <li parentName="ul">{`Booking.legs[].id: Unique identifier (UUID) for the leg`}</li>
      <li parentName="ul">{`Booking.legs[].leg: The state of the leg`}</li>
      <li parentName="ul">{`Booking.legs[].departureTime: When the leg was created`}</li>
      <li parentName="ul">{`Booking.legs[].actualDepartureTime: When the leg actually started (state = IN_USE)`}</li>
      <li parentName="ul">{`Booking.legs[].arrivalTime/actualArrivalTime: When the leg was ended`}</li>
      <li parentName="ul">{`Booking.legs[].from: Where the trip started (coordinates)`}</li>
      <li parentName="ul">{`Booking.legs[].to: Where the trip ended (coordinates), not required`}</li>
      <li parentName="ul">{`Booking.legs[].pricing: Pricing plan for the leg. Does not include the current price of the trip`}</li>
      <li parentName="ul">{`Booking.legs[].asset: Information about the asset (vehicle)`}</li>
      <li parentName="ul">{`Booking.legs[].asset.id: Unique identifier (UUID) for the asset. Should be the same as the GBFS dynamic ID`}</li>
      <li parentName="ul">{`Booking.legs[].asset.stateOfCharge: Current battery level for the asset`}</li>
      <li parentName="ul">{`Booking.legs[].asset.maxRange: Current range for the asset in KM`}</li>
      <li parentName="ul">{`Booking.legs[].asset.assetType.id: Reference to a AssetType. Required in TOMP, but we have set a static fallback since we dont use it`}</li>
      <li parentName="ul">{`Booking.legs[].asset.overriddenProperties.meta.vehicleCode: Your internal vehicleCode`}</li>
    </ul>
    <p>{`Here are a list of all the endpoints you need to implement (with examples) to support the TOMP API standard:`}</p>
    <h4 {...{
      "id": "get-meta-data-describes-the-running-implementations"
    }}>{`Get meta data (describes the running implementations)`}</h4>
    <p>{`Description: This endpoint returns meta data about the running implementation.
This includes what endpoints are implemented and what versions of the TOMP standard is supported.`}</p>
    <ExpandablePanel title="Example response: GET /operator/meta" mdxType="ExpandablePanel">
    <pre>{`[
    {
        "baseUrl": "BASE_URL",
        "endpoints": [
            {
                "path": "/operator/meta",
                "method": "GET",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/bookings/one-stop",
                "method": "POST",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/planning/offers",
                "method": "POST",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/legs/{id}/events",
                "method": "POST",
                "eventType": "SET_IN_USE",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/legs/{id}/events",
                "method": "POST",
                "eventType": "START_FINISHING",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/legs/{id}/events",
                "method": "POST",
                "eventType": "FINISH",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/legs/{id}/events",
                "method": "POST",
                "eventType": "PAUSE",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/bookings",
                "method": "GET",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/bookings/{id}",
                "method": "GET",
                "status": "IMPLEMENTED"
            },
            {
                "path": "/legs/{id}",
                "method": "GET",
                "status": "IMPLEMENTED"
            }
        ],
        "processIdentifiers": {
            "planning": [],
            "general": [],
            "operatorInformation": [],
            "booking": [],
            "tripExecution": [],
            "payment": [],
            "support": []
        },
        "scenarios": [
            "PAY_WHEN_FINISHED"
        ],
        "version": "1.5.0",
        "steps": null
    }
]`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "get-vehicleidassetid-gbfs-given-qr-code"
    }}>{`Get vehicleId/assetId (GBFS) given QR-code`}</h4>
    <p>{`Description: This endpoint is used to get the vehicleId/assetId given a QR-code. The QR-code is a unique identifier for the vehicle.`}</p>
    <ExpandablePanel title="Example request: GET /planning/offers" mdxType="ExpandablePanel">
    <pre>{`{
  "from": {
    "coordinates": {
      "lng": 0.00,
      "lat": 0.00
    },
    "extraInfo": {
      "vehicleCode": "ABCD1234"
    }
  }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response: GET /planning/offers" mdxType="ExpandablePanel">
    <pre>{`{
  "validUntil": "2024-08-07T05:53:04.758",
  "options": [
    {
      "legs": [
        {
          "asset": {
            "id": "TST:Vehicle:ea110245-131d-3109-ba91-b5bd57701934",
            "stateOfCharge": 100,
            "overriddenProperties": {
              "meta": {
                "vehicleCode": "ABCD1234"
              }
            }
          }
        }
      ]
    }
  ]
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "create-a-booking"
    }}>{`Create a booking`}</h4>
    <p>{`Description: This endpoint creates a booking for a trip. The booking is created with a list of legs, which includes information about the assets (vehicle).
Pricing on the booking is not mandatory before the state of all legs are set to START_FINISHING. The pricing on each leg represents the pricing plan, and
the pricing on the booking represents the total price for the trip.`}</p>
    <ExpandablePanel title="Example request: POST /bookings/one-stop" mdxType="ExpandablePanel">
    <pre>{`{
    "from": {
        "lng": 0.00,
        "lat": 0.00
    },
    "useAssets": [
        "TST:Vehicle:ea110245-131d-3109-ba91-b5bd57701934"
    ],
    "customer": {
        "id": "1234"
    }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response: POST /bookings/one-stop" mdxType="ExpandablePanel">
    <pre>{`{
    "departureTime": "2025-01-17T14:02:11.066Z",
    "legs": [
        {
            "from": {
                "lng": 0.00,
                "lat": 0.00
            },
            "id": "18aaa569-5be8-46ea-ba0c-c02e1ba0a54e",
            "state": "IN_USE",
            "asset": {
                "overriddenProperties": {
                    "meta": {
                        "vehicleCode": "ABCD1234"
                    }
                },
                "stateOfCharge": 100,
                "maxRange": 28400,
                "id": "TST:Vehicle:ea110245-131d-3109-ba91-b5bd57701934",
                "isDisabled": false
            },
            "pricing": {
                "estimated": false,
                "parts": [
                    {
                        "amount": 10.0,
                        "type": "FIXED",
                        "currencyCode": "NOK"
                    },
                    {
                        "unitType": "MINUTE",
                        "amount": 2.5,
                        "units": 1,
                        "type": "FLEX",
                        "currencyCode": "NOK"
                    },
                    {
                        "amount": 0.9,
                        "name": "Insurance fee",
                        "type": "FIXED",
                        "currencyCode": "NOK"
                    }
                ]
            }
        }
    ],
    "from": {
        "lng": 0.00,
        "lat": 0.00
    },
    "id": "TST:Booking:ea110245-131d-3109-ba91-b5bd57701934,
    "state": "CONFIRMED",
    "customer": {
        "id": "1234"
    },
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "change-the-state-of-a-leg"
    }}>{`Change the state of a leg`}</h4>
    <p>{`Description: This endpoint is used to change the state of a leg. The state of a leg can be changed to IN_USE, PAUSE, START_FINISHING or FINISH.
If its not possible to pause the vehicle, you dont need to support IN_USE or PAUSE events and
you can set the leg state to IN_USE when creating the booking.
When the state of a leg is set to START_FINISHING, the actualArrivalTime should be set.`}</p>
    <ExpandablePanel title="Example request: POST /legs/{id}/events with START_FINISHING" mdxType="ExpandablePanel">
    <pre>{`{
    "event": "START_FINISHING",
    "time" = "2025-01-17T14:02:11.066Z",
    "asset" = "TST:Vehicle:ea110245-131d-3109-ba91-b5bd57701934",
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response: POST /legs/{id}/events with START_FINISHING" mdxType="ExpandablePanel">
    <pre>{`{
    "from": {
        "lng": 0.00,
        "lat": 0.00
    },
    "id": "18aaa569-5be8-46ea-ba0c-c02e1ba0a54e",
    "state": "FINISHING",
    "asset": {
        "overriddenProperties": {
            "meta": {
                "vehicleCode": "ABCD1234"
            }
        },
        "stateOfCharge": 100,
        "maxRange": 28400,
        "id": "TST:Vehicle:ea110245-131d-3109-ba91-b5bd57701934",
        "isDisabled": false
    },
    "pricing": {
        "estimated": false,
        "parts": [
            {
                "amount": 10.0,
                "type": "FIXED",
                "currencyCode": "NOK"
            },
            {
                "unitType": "MINUTE",
                "amount": 2.5,
                "units": 1,
                "type": "FLEX",
                "currencyCode": "NOK"
            },
            {
                "amount": 0.9,
                "name": "Insurance fee",
                "type": "FIXED",
                "currencyCode": "NOK"
            }
        ]
    }
}`}</pre>
    </ExpandablePanel>
    <br />
    <ExpandablePanel title="Example request: POST /legs/{id}/events with FINISH" mdxType="ExpandablePanel">
    <pre>{`{
    "event": "FINISH",
    "time" = "2025-01-17T14:02:11.066Z",
    "url" = "URL_TO_PARKING_IMAGE",
    "asset" = "TST:Vehicle:ea110245-131d-3109-ba91-b5bd57701934",
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response: POST /legs/{id}/events with FINISH" mdxType="ExpandablePanel">
    <pre>{`{
    "from": {
        "lng": 0.00,
        "lat": 0.00
    },
    "id": "18aaa569-5be8-46ea-ba0c-c02e1ba0a54e,
    "state": "FINISHED",
    "asset": {
        "overriddenProperties": {
            "meta": {
                "vehicleCode": "ABCD1234"
            }
        },
        "stateOfCharge": 100,
        "maxRange": 28400,
        "id": "TST:Vehicle:ea110245-131d-3109-ba91-b5bd57701934",
        "isDisabled": false
    },
    "pricing": {
        "estimated": false,
        "parts": [
            {
                "amount": 10.0,
                "type": "FIXED",
                "currencyCode": "NOK"
            },
            {
                "unitType": "MINUTE",
                "amount": 2.5,
                "units": 1,
                "type": "FLEX",
                "currencyCode": "NOK"
            },
            {
                "amount": 0.9,
                "name": "Insurance fee",
                "type": "FIXED",
                "currencyCode": "NOK"
            }
        ]
    }
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "get-a-leg-a-booking-or-all-bookings"
    }}>{`Get a leg, a booking or all bookings`}</h4>
    <ExpandablePanel title="Example response: GET /legs/{id}" mdxType="ExpandablePanel">
    <pre>{`{
    "departureTime": "2025-01-17T14:02:11.066Z",
    "actualDepartureTime": "2025-01-17T14:02:11.082Z",
    "arrivalTime": "2025-01-17T14:04:20.926Z",
    "actualArrivalTime": "2025-01-17T14:04:20.926Z",
    "from": {
        "coordinates": {
            "lng": 10.745386,
            "lat": 59.908882
        }
    },
    "id": "18aaa569-5be8-46ea-ba0c-c02e1ba0a54e",
    "state": "FINISHED",
    "asset": {
        "overriddenProperties": {
            "meta": {
                "vehicleCode": "183112"
            }
        },
        "stateOfCharge": 64,
        "maxRange": 28400,
        "id": "TST:Vehicle:ea183112-79db-3976-995b-1d46423c93ce",
        "isDisabled": false
    },
    "pricing": {
        "estimated": false,
        "parts": [
            {
                "amount": 10.0,
                "type": "FIXED",
                "currencyCode": "NOK"
            },
            {
                "unitType": "MINUTE",
                "amount": 2.5,
                "units": 1,
                "type": "FLEX",
                "currencyCode": "NOK"
            },
            {
                "amount": 0.9,
                "name": "Insurance fee",
                "type": "FIXED",
                "currencyCode": "NOK"
            }
        ]
    }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response: GET /bookings/{id}" mdxType="ExpandablePanel">
    <pre>{`{
    "departureTime": "2025-01-17T02:43:54.734Z",
    "arrivalTime": "2025-01-17T02:46:17.412Z",
    "legs": [
        {
            "departureTime": "2025-01-17T02:43:54.734Z",
            "actualDepartureTime": "2025-01-17T02:45:32.162Z",
            "arrivalTime": "2025-01-17T02:46:17.412Z",
            "actualArrivalTime": "2025-01-17T02:46:17.412Z",
            "from": {
                "coordinates": {
                    "lng": 119.98905,
                    "lat": 31.813826
                }
            },
            "id": "18aaa569-5be8-46ea-ba0c-c02e1ba0a54e",
            "state": "FINISHED",
            "asset": {
                "overriddenProperties": {
                    "meta": {
                        "vehicleCode": "ABCD1234"
                    }
                },
                "stateOfCharge": 0,
                "maxRange": 0,
                "id": "TST:Vehicle:ea999992-8d9c-3570-be06-fb3cb1b958fa",
                "isDisabled": false
            },
            "pricing": {
                "estimated": false,
                "parts": [
                    {
                        "amount": 0.0,
                        "type": "FIXED",
                        "currencyCode": "NOK"
                    },
                    {
                        "unitType": "MINUTE",
                        "amount": 1.0,
                        "units": 1,
                        "type": "FLEX",
                        "currencyCode": "NOK"
                    },
                    {
                        "amount": 1.0,
                        "name": "Insurance fee",
                        "type": "FIXED",
                        "currencyCode": "NOK"
                    }
                ]
            }
        }
    ],
    "from": {
        "coordinates": {
            "lng": 10.793742,
            "lat": 59.917294
        }
    },
    "id": "18aaa569-5be8-46ea-ba0c-c02e1ba0a54e",
    "state": "FINISHED",
    "pricing": {
        "estimated": false,
        "parts": [
            {
                "bankAccount": null,
                "amount": 2.0,
                "vatCountryCode": "NO",
                "vatRate": 26,
                "amountExVat": 1.59,
                "details": {
                    "estimated": false,
                    "parts": [
                        {
                            "amount": 0.0,
                            "type": "FIXED",
                            "currencyCode": "NOK"
                        },
                        {
                            "unitType": "MINUTE",
                            "amount": 1.0,
                            "units": 1,
                            "type": "FLEX",
                            "currencyCode": "NOK"
                        },
                        {
                            "amount": 1.0,
                            "name": "Insurance fee",
                            "type": "FIXED",
                            "currencyCode": "NOK"
                        }
                    ]
                },
                "state": "TO_INVOICE",
                "category": "FARE",
                "currencyCode": "NOK",
            }
        ]
    },
    "customer": {
        "id": "1234"
    }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response: GET /bookings" mdxType="ExpandablePanel">
    <pre>{`[{
    "departureTime": "2025-01-17T02:43:54.734Z",
    "arrivalTime": "2025-01-17T02:46:17.412Z",
    "legs": [
        {
            "departureTime": "2025-01-17T02:43:54.734Z",
            "actualDepartureTime": "2025-01-17T02:45:32.162Z",
            "arrivalTime": "2025-01-17T02:46:17.412Z",
            "actualArrivalTime": "2025-01-17T02:46:17.412Z",
            "from": {
                "coordinates": {
                    "lng": 119.98905,
                    "lat": 31.813826
                }
            },
            "id": "18aaa569-5be8-46ea-ba0c-c02e1ba0a54e",
            "state": "FINISHED",
            "asset": {
                "overriddenProperties": {
                    "meta": {
                        "vehicleCode": "ABCD1234"
                    }
                },
                "stateOfCharge": 0,
                "maxRange": 0,
                "id": "TST:Vehicle:ea999992-8d9c-3570-be06-fb3cb1b958fa",
                "isDisabled": false
            },
            "pricing": {
                "estimated": false,
                "parts": [
                    {
                        "amount": 0.0,
                        "type": "FIXED",
                        "currencyCode": "NOK"
                    },
                    {
                        "unitType": "MINUTE",
                        "amount": 1.0,
                        "units": 1,
                        "type": "FLEX",
                        "currencyCode": "NOK"
                    },
                    {
                        "amount": 1.0,
                        "name": "Insurance fee",
                        "type": "FIXED",
                        "currencyCode": "NOK"
                    }
                ]
            }
        }
    ],
    "from": {
        "coordinates": {
            "lng": 10.793742,
            "lat": 59.917294
        }
    },
    "id": "18aaa569-5be8-46ea-ba0c-c02e1ba0a54e",
    "state": "FINISHED",
    "pricing": {
        "estimated": false,
        "parts": [
            {
                "bankAccount": null,
                "amount": 2.0,
                "vatCountryCode": "NO",
                "vatRate": 26,
                "amountExVat": 1.59,
                "details": {
                    "estimated": false,
                    "parts": [
                        {
                            "amount": 0.0,
                            "type": "FIXED",
                            "currencyCode": "NOK"
                        },
                        {
                            "unitType": "MINUTE",
                            "amount": 1.0,
                            "units": 1,
                            "type": "FLEX",
                            "currencyCode": "NOK"
                        },
                        {
                            "amount": 1.0,
                            "name": "Insurance fee",
                            "type": "FIXED",
                            "currencyCode": "NOK"
                        }
                    ]
                },
                "state": "TO_INVOICE",
                "category": "FARE",
                "currencyCode": "NOK",
            }
        ]
    },
    "customer": {
        "id": "1234"
    }
}]`}</pre>
    </ExpandablePanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      