import benefitsChangelog from '@pages/benefits/docs/changelog-benefits.json5';
import consentsChangelog from '@pages/consents/docs/changelog-consents.json5';
import profilesChangelog from '@pages/profiles/docs/changelog-profiles.json5';
import customersOrchestratorChangelog from "../../../../../pages/customers/docs/changelog-customers-orchestrator.json5";
import * as React from 'react';
export default {
  benefitsChangelog,
  consentsChangelog,
  profilesChangelog,
  customersOrchestratorChangelog,
  React
};